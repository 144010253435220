import * as React from "react";

interface PageScrollUpProps {
  top?: number;
  behavior?: "auto" | "smooth";
}

export const usePageScrollUp = (props?: PageScrollUpProps) => {
  React.useEffect(() => {
    window.scrollTo({ top: props?.top || 0, behavior: props?.behavior });
  }, [props]);
};
